import { paginationDataType } from "../common/Types";

export const ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_SUCCESS: '/reset-password-success',
  CHECK_EMAIL: '/check-email',
  USER_DETAIL: '/users/:uid',
  COACH_DETAIL: '/coaches/:cid',
  DASHBOARD: '/dashboard',
  ACCOUNTS: '/accounts',
  USERS: '/users',
  COACHES: '/coaches',
  JOURNEY: '/journey',
  REPORTS: '/reports',
  INVOICES: '/invoices',
  REVIEWS: '/reviews',
  CONTENT: '/content',
  SUB_ADMINS: '/sub-admins',
  SUB_ADMIN_DETAIL: '/sub-admins/:sid',
  AIRTABLE_DATA_SYNC: '/airtable-data-sync'
};

export const PER_PAGE_RECORDS = 10;

export const PAGE_PER_RECORD_OPTIONS = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 }
];

export const LOADER_TYPE = {
  FULL_SCREEN: 'FULL_SCREEN',
  TABLE: 'TABLE'
};

export const SORTING = {
  DESC: -1,
  ASC: 1
};

export const USER_STATUS = {
  UN_BLOCKED: 'UN_BLOCKED',
  BLOCKED: 'BLOCKED',
  DELETED: 'DELETED'
};

export const genderList = [
  { label: "Male", value: 'Male' },
  { label: "Female", value: 'Female' },
  { label: "Other", value: 'Other' },
  { label: "Prefer not to say", value: 'Prefer not to say' },
];

export const USER_TYPE = {
  USER: 'USER',
  COACH: 'COACH',
  SUB_ADMIN: 'SUB_ADMIN'
};

export const ADMIN_USER_TYPE = 'ADMIN';

export const PERMISSION = {
  DASHBOARD: 'Dashboard',
  USER: 'User (Professional+Advisor)',
  COACH: 'Coach',
  SUB_ADMIN: "Sub-Admin",
  INVOICE: 'Invoice Management',
  REPORT: "Report",
  REVIEWS: 'Reviews'
};

export const paginationData: paginationDataType = Object.freeze({
  list: [],
  total: 0,
  pageNo: 1,
});

export const MODULES = {
  PHONE_NUMBER: {
    DEFAULT_COUNTRY_CODE: 'us',
    DEFAULT_DIAL_CODE: 1
  },
  USERS: {
    ROLE: {
      PROFESSIONALS: 'professionals',
      ADVISORS: "advisors"
    },
    STATUS_LIST: [
      { label: "Active users", value: USER_STATUS.UN_BLOCKED },
      { label: "Blocked users", value: USER_STATUS.BLOCKED },
    ],
    SETTING_LIST: [
      { label: "Block User", value: 'block_user' },
      { label: "Reset Password", value: 'reset_password' },
      { label: "Delete User", value: 'delete_account' },
    ]
  },
  COACHES: {
    STATUS_LIST: [
      { label: "Active coaches", value: USER_STATUS.UN_BLOCKED },
      { label: "Blocked coaches", value: USER_STATUS.BLOCKED },
    ],
    SETTING_LIST: [
      { label: "Block Coach", value: 'block_coach' },
      { label: "Reset Password", value: 'reset_password' },
      { label: "Delete Coach", value: 'delete_account' },
    ]
  },
  SUB_ADMINS: {
    STATUS_LIST: [
      { label: "Active sub-admins", value: USER_STATUS.UN_BLOCKED },
      { label: "Blocked sub-admins", value: USER_STATUS.BLOCKED },
    ],
    SETTING_LIST: [
      { label: "Block Sub-Admin", value: 'block_sub_admin' },
      { label: "Reset Password", value: 'reset_password' },
      { label: "Delete Sub-Admin", value: 'delete_account' },
    ]
  }
};
