import React from 'react';
import { Link } from 'react-router-dom';

import { DotsLoaderView, ModalView, RatingStars } from '..';
import {
    BlockIcon, DeleteIcon, DropDownIcon, DropupIcon, EditIcon,
    ReplyIcon, SortingArrow, UnblockIcon
} from '../../../assets/images';
import { SORTING } from '../../../helpers';
import { titleCase } from '../../../utils';
import { sortingFilterType } from '../../Types';
import { modalTypes } from './config';

interface Props {
    actions: boolean;
    expandedRows: Array<any>;
    actionConfig?: any;
    isLoading: boolean;
    isShowColumns: boolean;
    isTableLoading: boolean;
    columns: Array<any>;
    popupHeading: string;
    replyComponent: React.FC<any>;
    expandedComponent: React.FC<any>;
    recordId: string;
    isEditAction: boolean;
    isReplyAction: boolean;
    isBlockUnblockAction: boolean;
    list: Array<any>;
    editComponent: React.FC<any>;
    sortingFilters: sortingFilterType;
    openedModalType: string;
    onDeleteSubmit: () => void;
    onToggleModal: (type?: string, rid?: string) => void;
    onGetStatusClassName: (status: string) => void;
    onBlockSubmit: () => void;
    onUnBlockSubmit: () => void;
    onSorting: (filters: sortingFilterType) => void;
    onExpandRow: (rowIndex: number) => void;
}

interface SortingIconsProps {
    name: string;
    sorting: boolean;
    filters: sortingFilterType;
}

function AscIcon() {
    return <img
        src={SortingArrow}
        style={{ transform: 'rotate(180deg)' }}
        alt='Asc'
    />;
}

function DescIcon() {
    return <img
        src={SortingArrow}
        alt='Desc'
    />;
}

function SortingIcons({ name, sorting, filters }: SortingIconsProps) {

    if (!sorting) {
        return null;
    }

    return <div className='sort-arrow'>
        {(filters.sortBy && filters.sortOrder && filters.sortBy === name)
            ? <>
                {filters.sortOrder === SORTING.ASC && <AscIcon />}
                {filters.sortOrder === SORTING.DESC && <DescIcon />}
            </>
            : <>
                <AscIcon />
                <DescIcon />
            </>
        }
    </div>;
}

function TableViewComponent({
    actions,
    expandedRows,
    actionConfig,
    columns,
    recordId,
    isReplyAction,
    isEditAction,
    isShowColumns,
    isBlockUnblockAction,
    popupHeading,
    replyComponent: ReplyComponent,
    expandedComponent: ExpandedComponent,
    isLoading,
    isTableLoading,
    openedModalType,
    list,
    editComponent: EditComponent,
    sortingFilters,
    onDeleteSubmit,
    onToggleModal,
    onGetStatusClassName,
    onBlockSubmit,
    onUnBlockSubmit,
    onSorting,
    onExpandRow
}: Props) {
    const capitalizeHeading = React.useMemo(() => titleCase(popupHeading), [popupHeading]);

    return <>
        <div className='table-wrapper'>
            <table>
                {isShowColumns && <thead>
                    <tr>
                        {columns.map((col, index) => {
                            const { name, sorting = false, key } = col;
                            return (
                                <td
                                    key={`col-${index}`}
                                    className={sorting ? 'hand-icon' : ''}
                                    onClick={() => sorting && onSorting({ sortBy: key, sortOrder: sortingFilters.sortOrder === SORTING.DESC ? SORTING.ASC : SORTING.DESC })}
                                >
                                    {name}
                                    <SortingIcons name={key} sorting={sorting} filters={sortingFilters} />
                                </td>
                            );
                        })}
                        {actions && <td key={`col-${columns.length}`}>{actionConfig.colName}</td>}
                    </tr>
                </thead>}
                <tbody>
                    {list.map((item, i) => {
                        const isExpanded = expandedRows.indexOf(i) > -1;

                        return (
                            <>
                                <tr key={`row-${i}`}>
                                    {columns.map((row, j) => {
                                        const { key, link = '', title = '', redirection = false, button = false, rating = false, bgColors = {}, bold = false } = row;
                                        const value = item[key] === undefined || item[key] === '' ? '-' : item[key];
                                        const itemProps: any = { key: `row-item-${i}.${j}` };

                                        if (bold) {
                                            itemProps.style = { fontWeight: 'bold' };
                                        }

                                        if (!!link) {
                                            return (
                                                <td {...itemProps}>
                                                    <Link to={`${link}/${item.id}`}>{value}</Link>
                                                </td>
                                            );
                                        }

                                        if (redirection && value !== '-') {
                                            return (
                                                <td {...itemProps}>
                                                    <Link
                                                        to='#'
                                                        onClick={() => window.open(value, '_blank')}
                                                    >
                                                        {title}
                                                    </Link>
                                                </td>
                                            );
                                        }

                                        if (button) {
                                            const props: any = { className: `status ${onGetStatusClassName(value)}` };
                                            if (bgColors[value]) {
                                                props.style = { backgroundColor: bgColors[value], borderColor: bgColors[value] };
                                            }
                                            return (
                                                <td {...itemProps}>
                                                    <span {...props}>
                                                        {value}
                                                    </span>
                                                </td>
                                            );
                                        }

                                        if (rating) {
                                            return (
                                                <td {...itemProps}>
                                                    <RatingStars value={value} />
                                                </td>
                                            );
                                        }

                                        return <td {...itemProps}>{value}</td>;
                                    })}
                                    {actions &&
                                        <td>
                                            {isReplyAction && <>
                                                <button
                                                    className='action'
                                                    title='Reply'
                                                    onClick={() => onToggleModal(modalTypes.REPLY, item.id)}
                                                >
                                                    <img src={ReplyIcon} alt='Reply' />
                                                </button>
                                            </>}
                                            {isEditAction &&
                                                <button
                                                    className='action'
                                                    title='Edit'
                                                    onClick={() => onToggleModal(modalTypes.EDIT, item.id)}
                                                >
                                                    <img src={EditIcon} alt='Edit' />
                                                </button>
                                            }
                                            {actionConfig.actions.delete && <button
                                                className='action'
                                                title='Delete'
                                                onClick={() => onToggleModal(modalTypes.DELETE, item.id)}
                                            >
                                                <img src={DeleteIcon} alt='Delete' />
                                            </button>}
                                            {actionConfig.actions.collapse && <button
                                                onClick={() => onExpandRow(i)}
                                            >
                                                <img src={(isExpanded ? DropupIcon : DropDownIcon)} alt='Arrow' />
                                            </button>}
                                            {isBlockUnblockAction && <>
                                                {item.status !== 'Blocked' &&
                                                    <button
                                                        className='action'
                                                        title='Block'
                                                        onClick={() => onToggleModal(modalTypes.BLOCK, item.id)}
                                                    >
                                                        <img src={BlockIcon} alt='Block' />
                                                    </button>
                                                }
                                                {item.status !== 'Active' &&
                                                    <button
                                                        className='action'
                                                        title='Un Block'
                                                        onClick={() => onToggleModal(modalTypes.UNBLOCK, item.id)}
                                                    >
                                                        <img src={UnblockIcon} alt='Unblock' />
                                                    </button>
                                                }
                                            </>}
                                        </td>
                                    }
                                </tr>
                                {isExpanded && <ExpandedComponent detail={item} />}
                            </>
                        );
                    })}
                    {!list.length && !isLoading && !isTableLoading && (
                        <tr>
                            <td colSpan={8} className='text-center text-spacing'>
                                No Record Found
                            </td>
                        </tr>
                    )}

                    {/* dots-loader */}
                    <DotsLoaderView />
                </tbody>
            </table>
        </div>
        {actions &&
            <>
                <ModalView
                    isOpen={openedModalType === modalTypes.DELETE}
                    title={`Delete ${capitalizeHeading}`}
                    header={`Are you sure want to delete the ${popupHeading}?`}
                    wrapperClassName='delete-modal'
                    onToggle={() => onToggleModal(modalTypes.DELETE)}
                    onSubmit={onDeleteSubmit}
                />
                <ModalView
                    isOpen={openedModalType === modalTypes.BLOCK}
                    title={`Block ${capitalizeHeading}`}
                    header={`Are you sure want to block the ${popupHeading}?`}
                    wrapperClassName='block-modal'
                    onToggle={() => onToggleModal(modalTypes.BLOCK)}
                    onSubmit={onBlockSubmit}
                />
                <ModalView
                    isOpen={openedModalType === modalTypes.UNBLOCK}
                    title={`Unblock ${capitalizeHeading}`}
                    header={`Are you sure want to unblock the ${popupHeading}?`}
                    wrapperClassName='unblock-modal'
                    onToggle={() => onToggleModal(modalTypes.UNBLOCK)}
                    onSubmit={onUnBlockSubmit}
                />
                <EditComponent
                    isOpen={openedModalType === modalTypes.EDIT}
                    detail={list.find(rec => rec.id === recordId)}
                    onToggle={() => onToggleModal(modalTypes.EDIT)}
                />
                <ReplyComponent
                    isOpen={openedModalType === modalTypes.REPLY}
                    detail={list.find(rec => rec.id === recordId)}
                    onToggle={() => onToggleModal(modalTypes.REPLY)}
                />
            </>
        }
    </>;
}

export default TableViewComponent;
