import React from 'react';

import { useAppSelector } from '../../custom-hooks';

import { sortingFilterType } from '../../Types';
import TableViewComponent from './Component';

interface Props {
  actions: boolean;
  actionConfig?: any;
  columns: Array<any>;
  isEditAction: boolean;
  isShowColumns: boolean;
  isBlockUnblockAction: boolean;
  isReplyAction: boolean;
  list: Array<any>;
  popupHeading: string;
  editComponent: React.FC<any>;
  replyComponent: React.FC<any>;
  expandedComponent: React.FC<any>;
  sortingFilters: sortingFilterType;
  onDeleteRecord: (rid: string) => void;
  onBlockRecord: (rid: string) => void;
  onUnBlockRecord: (rid: string) => void;
  onSortingFilterOnChange: (filters: sortingFilterType) => void;
}

function getStatusClassName(status: string) {
  if (status) {
    switch (status) {
      case 'Active':
      case 'Paid':
        return 'sucess';
      case 'Inactive':
      case 'Scheduled':
        return 'gray';
      default:
        return 'danger';
    }
  } else {
    return '';
  }
}

function TableView({ actions, actionConfig, columns, expandedComponent, popupHeading, isEditAction, isShowColumns, isBlockUnblockAction, isReplyAction, list, replyComponent, editComponent, sortingFilters, onDeleteRecord, onBlockRecord, onUnBlockRecord, onSortingFilterOnChange }: Props) {
  const { isLoading, isTableLoading } = useAppSelector(state => state.common);

  const [openedModalType, setOpenedModalType] = React.useState<string>('');
  const [recordId, setRecordId] = React.useState<string>('');
  const [expandedRows, setExpandedRows] = React.useState<Array<any>>([]);

  const handleExpandedRows = React.useCallback((mid: number) => {
    setExpandedRows(pState => {
      const temp = pState.slice();
      const index = pState.indexOf(mid);
      if (index > -1) {
        delete temp[index];
      } else {
        temp.push(mid);
      }
      return temp;
    });
  }, []);

  const handleOnToggleModal = React.useCallback((type?: string, rid?: string) => {
    if (rid) {
      setRecordId(rid);
    }
    setOpenedModalType((pState) => (type && pState === type ? '' : type || ''));
  }, []);

  const handleOnDeleteSubmit = React.useCallback(() => {
    onDeleteRecord(recordId);
    setRecordId('');
  }, [recordId, onDeleteRecord]);

  const handleOnBlockSubmit = React.useCallback(() => {
    onBlockRecord(recordId);
    setRecordId('');
  }, [recordId, onBlockRecord]);

  const handleOnUnBlockSubmit = React.useCallback(() => {
    onUnBlockRecord(recordId);
    setRecordId('');
  }, [recordId, onUnBlockRecord]);

  const handleOnSorting = React.useCallback((filters: sortingFilterType) => {
    if (onSortingFilterOnChange) {
      onSortingFilterOnChange(filters);
    }
  }, [onSortingFilterOnChange]);

  return (
    <TableViewComponent
      actions={actions}
      expandedRows={expandedRows}
      actionConfig={actionConfig}
      columns={columns}
      isEditAction={isEditAction}
      isShowColumns={isShowColumns}
      isReplyAction={isReplyAction}
      isBlockUnblockAction={isBlockUnblockAction}
      popupHeading={popupHeading}
      recordId={recordId}
      list={list}
      editComponent={editComponent}
      expandedComponent={expandedComponent}
      replyComponent={replyComponent}
      sortingFilters={sortingFilters}
      isLoading={isLoading}
      isTableLoading={isTableLoading}
      openedModalType={openedModalType}
      onDeleteSubmit={handleOnDeleteSubmit}
      onToggleModal={handleOnToggleModal}
      onGetStatusClassName={getStatusClassName}
      onBlockSubmit={handleOnBlockSubmit}
      onUnBlockSubmit={handleOnUnBlockSubmit}
      onSorting={handleOnSorting}
      onExpandRow={handleExpandedRows}
    />
  );
}

// Set default props
TableView.defaultProps = {
  actions: true,
  actionConfig: { colName: 'Actions', actions: { collapse: false, delete: true } },
  isEditAction: false,
  isReplyAction: false,
  isShowColumns: true,
  isBlockUnblockAction: true,
  popupHeading: 'user',
  editComponent: () => <></>,
  replyComponent: () => <></>,
  expandedComponent: () => <></>,
  sortingFilters: { sortBy: '', sortOrder: 1 },
  onDeleteRecord: () => { },
  onBlockRecord: () => { },
  onUnBlockRecord: () => { },
  onSortingFilterOnChange: () => { }
};

export default TableView;
