import { createAsyncThunk } from '@reduxjs/toolkit';

import { postDataApi, getDataApi, putDataApi } from '../../api/methods';

export const fetchCoaches = createAsyncThunk(
    'admin/coaches',
    (payload: any) => postDataApi({ path: 'admin/coaches', data: payload })
);

export const getCoach = createAsyncThunk(
    'admin/coaches/:cid',
    (payload: any) => getDataApi({ path: `admin/coaches/${payload.coachId}`, data: payload })
);

export const updateCoachStatus = createAsyncThunk(
    'admin/coaches/status',
    (payload: any) => putDataApi({ path: "admin/coaches/status", data: payload })
);

export const coachResetPassword = createAsyncThunk(
    'admin/coaches/reset-password',
    (payload: any) => putDataApi({ path: 'admin/coaches/reset-password', data: payload })
);

export const addCoach = createAsyncThunk(
    'admin/coaches/add',
    (payload: any) => postDataApi({ path: 'admin/coaches/add', data: payload })
);

export const updateCoach = createAsyncThunk(
    'admin/coaches',
    (payload: any) => putDataApi({ path: "admin/coaches", data: payload })
);
