import { createAsyncThunk } from "@reduxjs/toolkit";

import { getDataApi, postDataApi, putDataApi } from "../../api/methods";

export const fetchUsers = createAsyncThunk("admin/users", (payload: any) =>
  postDataApi({ path: "admin/users", data: payload })
);

export const getUser = createAsyncThunk("admin/users/:uid", (payload: any) => {
  const request = { ...payload };
  delete request.userId;
  return getDataApi({ path: `admin/users/${payload.userId}`, data: request });
});

export const updateUserStatus = createAsyncThunk(
  "admin/users/status",
  (payload: any) => putDataApi({ path: "admin/users/status", data: payload })
);

export const userResetPassword = createAsyncThunk(
  "admin/users/reset-password",
  (payload: any) =>
    putDataApi({ path: "admin/users/reset-password", data: payload })
);

export const addUser = createAsyncThunk("admin/users/add", (payload: any) =>
  postDataApi({ path: "admin/users/add", data: payload })
);

export const updateUserPlan = createAsyncThunk(
  "admin/users/plan",
  (payload: any) => putDataApi({ path: "admin/users/plan", data: payload })
);

export const updateUser = createAsyncThunk("admin/users", (payload: any) =>
  putDataApi({ path: "admin/users", data: payload })
);

export const fetchUserPaymentHistory = createAsyncThunk(
  "admin/payment-history",
  (payload: any) => getDataApi({ path: "admin/payment-history", data: payload })
);

export const fetchSubscriptionCard = createAsyncThunk(
  `admin/subscription/recent-card/{userId}`,
  (payload: any) =>
    getDataApi({
      path: `admin/subscription/recent-card/${payload.userId}`,
      data: payload,
    })
);

export const fetchUserPlans = createAsyncThunk(
  "admin/subscription/plans",
  (payload: any) => getDataApi({ path: "admin/subscription/plans", data: payload })
);

export const fetchTrackJourney = createAsyncThunk(
  "admin/users/track-journey",
  (payload: any) => postDataApi({ path: "admin/users/track-journey", data: payload })
);

export const fetchTrackJourneyDetails = createAsyncThunk(
  "admin/users/track-journey/details",
  (payload: any) => postDataApi({ path: "admin/users/track-journey/details", data: payload })
);
