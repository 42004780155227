import React from 'react';

import { SettingsIcon } from '../../../assets/images';
import { ViewOutsideClick } from '../../custom-hooks';

interface Props {
    settings: Array<any>;
    onSelect: (option: any) => void;
}

function SettingButton({settings,  onSelect }: Props) {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleOpenMenu = React.useCallback(() => {
        setIsOpen(pState => !pState);
    }, []);

    return (
        <ViewOutsideClick isOpen={isOpen} onElementClick={setIsOpen}>
            <div className='setting-options'>
                <button className='setting-btn' type="button" onClick={handleOpenMenu}>
                    <img src={SettingsIcon} alt="Icon" />
                </button>
                <ul className={`${isOpen ? 'active' : ''}`}>
                    {settings.map((set, index) => {
                        return <li
                            key={index}
                            onClick={() => {
                                onSelect(set);
                                handleOpenMenu();
                            }}
                        >
                            {set.label}
                        </li>;
                    })}
                </ul>
            </div>
        </ViewOutsideClick>
    );
}

export default SettingButton;
