import {
    AccountsIcon, AirSidebarIcon, CoachIcon, DashboardIcon, InvoiceIcon, LogoutIcon, ReportIcon,
    ReviewRating,
    SubadminIcon, UserIcon
} from '../../../assets/images';
import { PERMISSION, ROUTES } from '../../../helpers';
import { menuType } from './Types';

export const menuList: Array<menuType> = [
    { name: 'Dashboard', permission: PERMISSION.DASHBOARD, icon: DashboardIcon, pathname: ROUTES.DASHBOARD },
    { name: 'Users', permission: PERMISSION.USER, icon: UserIcon, pathname: ROUTES.USERS },
    { name: 'Coaches', permission: PERMISSION.COACH, icon: CoachIcon, pathname: ROUTES.COACHES },
    { name: 'Sub-admin', permission: PERMISSION.SUB_ADMIN, icon: SubadminIcon, pathname: ROUTES.SUB_ADMINS },
    { name: 'Coaching Invoices', permission: PERMISSION.INVOICE, icon: InvoiceIcon, pathname: ROUTES.INVOICES },
    { name: 'Reports', permission: PERMISSION.REPORT, icon: ReportIcon, pathname: ROUTES.REPORTS },
    { name: 'Reviews', permission: PERMISSION.REVIEWS, icon: ReviewRating, pathname: ROUTES.REVIEWS },
];

export const accountMenu: menuType =
    { name: 'Accounts', icon: AccountsIcon, pathname: ROUTES.ACCOUNTS };

export const staticMenuList: Array<menuType> = [
    { name: 'Airtable Data Sync', icon: AirSidebarIcon, pathname: ROUTES.AIRTABLE_DATA_SYNC },
    { name: 'Logout', icon: LogoutIcon },
];
