import { lazy } from 'react';

import { PERMISSION, ROUTES } from '../helpers';
import { RouteType } from './types';

// Instead of regular import statements, we will use the approach -> lazy loading
const UsersWrapper = lazy(() => import('../pages/users'));
const LoginWrapper = lazy(() => import('../pages/auth/modules/login'));
const ForgotPasswordWrapper = lazy(() => import('../pages/auth/modules/forgot-password'));
const ResetPasswordWrapper = lazy(() => import('../pages/auth/modules/reset-password'));
const CheckEmailWrapper = lazy(() => import('../pages/auth/modules/check-email'));
const ResetPasswordSuccessWrapper = lazy(() => import('../pages/auth/modules/reset-password-success'));
const UserDetail = lazy(() => import('../pages/users/modules/user-detail'));
const CoachesWrapper = lazy(() => import('../pages/coaches'));
const CoachDetailWrapper = lazy(() => import('../pages/coaches/modules/coach-detail'));
const NotFoundWrapper = lazy(() => import('../pages/not-found'));
const ReportWrapper = lazy(() => import('../pages/reports'));
const SubAdminWrapper = lazy(() => import('../pages/sub-admin'));
const SubAdminDetailWrapper = lazy(() => import('../pages/sub-admin/modules/sub-admin-detail'));
const AccountsWrapper = lazy(() => import('../pages/accounts'));
const InvoiceWrapper = lazy(() => import('../pages/invoices'));
const DashboardWrapper = lazy(() => import('../pages/dashboard'));
const AirtableDataSync = lazy(() => import('../pages/airtable-data-sync'));
const Reviews = lazy(() => import('../pages/reviews'));

export const pageRoutes: Array<RouteType> = [
  /** Dashboard Routes */
  {
    name: 'dashboard',
    path: ROUTES.DASHBOARD,
    Component: DashboardWrapper,
    isPrivate: true,
    permission: PERMISSION.DASHBOARD
  },

  /** User Routes */
  {
    name: 'users',
    path: ROUTES.USERS,
    Component: UsersWrapper,
    isPrivate: true,
    permission: PERMISSION.USER
  },

  {
    name: 'user-detail',
    path: ROUTES.USER_DETAIL,
    Component: UserDetail,
    isPrivate: true,
    permission: PERMISSION.USER
  },

  /** Coach Routes */
  {
    name: 'coaches',
    path: ROUTES.COACHES,
    Component: CoachesWrapper,
    isPrivate: true,
    permission: PERMISSION.COACH
  },
  {
    name: 'coach-detail',
    path: ROUTES.COACH_DETAIL,
    Component: CoachDetailWrapper,
    isPrivate: true,
    permission: PERMISSION.COACH
  },

  /** Report Routes */
  {
    name: 'report',
    path: ROUTES.REPORTS,
    Component: ReportWrapper,
    isPrivate: true,
    permission: PERMISSION.REPORT
  },

  /** Sub-Admin Routes */
  {
    name: 'subAdmin',
    path: ROUTES.SUB_ADMINS,
    Component: SubAdminWrapper,
    isPrivate: true,
    permission: PERMISSION.SUB_ADMIN
  },
  {
    name: 'sub-admin-detail',
    path: ROUTES.SUB_ADMIN_DETAIL,
    Component: SubAdminDetailWrapper,
    isPrivate: true,
    permission: PERMISSION.SUB_ADMIN
  },

  /** Invoices Routes */
  {
    name: 'invoices',
    path: ROUTES.INVOICES,
    Component: InvoiceWrapper,
    isPrivate: true,
    permission: PERMISSION.INVOICE
  },

  /** Reviews Routes */
  {
    name: 'reviews',
    path: ROUTES.REVIEWS,
    Component: Reviews,
    isPrivate: true,
    permission: PERMISSION.REVIEWS
  },
];

export const commonRoutes: Array<RouteType> = [
  /** Airtable Data Sync Routes */
  {
    name: 'airtable data sync',
    path: ROUTES.AIRTABLE_DATA_SYNC,
    Component: AirtableDataSync,
    isPrivate: true,
  },

  /** Accounts Routes */
  {
    name: 'accounts',
    path: ROUTES.ACCOUNTS,
    Component: AccountsWrapper,
    isPrivate: true,
  },

  /** Public Routes */
  {
    name: 'forgot-password',
    path: ROUTES.FORGOT_PASSWORD,
    Component: ForgotPasswordWrapper,
    isPrivate: false,
  },
  {
    name: 'reset-password',
    path: ROUTES.RESET_PASSWORD,
    Component: ResetPasswordWrapper,
    isPrivate: false,
  },
  {
    name: 'reset-password-success',
    path: ROUTES.RESET_PASSWORD_SUCCESS,
    Component: ResetPasswordSuccessWrapper,
    isPrivate: false,
  },
  {
    name: 'check-email',
    path: ROUTES.CHECK_EMAIL,
    Component: CheckEmailWrapper,
    isPrivate: false,
  },
  {
    name: 'login',
    path: ROUTES.LOGIN,
    Component: LoginWrapper,
    isPrivate: false,
  },

  /** Unknown Route */
  {
    name: 'not-found',
    path: '*',
    Component: NotFoundWrapper,
    isPrivate: false,
  },
];
