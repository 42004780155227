import axios from 'axios';

import config from '../Config';
import { clearReduxState } from '../redux/features/authSlice';
import { store } from '../redux/store';
import { errorAlert } from '../utils';

// get header keys
const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
const offset: number = new Date().getTimezoneOffset();

// set axios instance
const customAxios = axios.create({
  baseURL: `${config.API_BASE_URL}/koda/api/`,
  timeout: 100000,
  headers: {
    'accept': 'application/json',
    'Content-type': 'application/json',
    timezone,
    offset,
    platform: config.PLATFORM,
    language: config.LANGUAGE,
    api_key: config.API_KEY
  },
});

/**
 * @function requestHandler: Set access token and username/password for api calls
 * @param {Object} request API request
 */
const requestHandler = (request: any) => {
  const accessToken: string = store?.getState()?.auth.accessToken || '';

  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken || ""}`;
  } else {
    request.headers['Authorization'] = 'Basic ' + btoa(`${config.USERNAME}:${config.PASSWORD}`);
  }

  return request;
};

/**
 * @function logout: Clearing the stored redux state and logged out from current session
 * @param {String} message Coming text message from backend
 */
function logout(message?: string) {
  store.dispatch(clearReduxState());
  if (message) {
    errorAlert(message);
  }
}

/**
 * @function responseHandler: Checking API response and logged out when session will expire
 * @param {Object} response API response
 */
const responseHandler = (response: any) => {
  if (response.data?.statusCode === 401) {
    logout(response.data.message);
  }

  return response;
};

/**
 * @function errorHandler: Checking API error response and logged out when session will expire
 * @param {Object} error API response
 */
const errorHandler = (error: any) => {
  if (error.response?.data?.statusCode === 401) {
    logout(error.response.data.message);
  }

  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
