import { userPlanType } from "../common/Types";

interface getPlanLabelType {
    plans: Array<userPlanType>;
    selected: string;
}

export function getPlanOption({ plans, selected }: getPlanLabelType) {
    let planOption: any = '';
    for (let plan of plans) {
        if (plan.options) {
            const isExists: any = plan.options.find((op: any) => op.value === selected);
            if (isExists) {
                planOption = isExists;
            }
        } else if (plan.value === selected) {
            planOption = plan;
        }
    }
    return planOption;
}

export function getPlanName(plan: string) {
    const replacedText = plan.replace(/silver/gi, (match: string) => {
        if (match.toLowerCase() === "silver") {
            return "Koda Member";
        } else {
            return "Koda Member"; // Handle other cases here if needed
        }
    });

    return replacedText;
}
